@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './vendor/lightgallery.css';

div::-webkit-scrollbar {
    display: none;
}

*:focus, *:active, *:hover { outline: none; }

html {
    background-color: #08041A;
}

/* Applied to the toast body when using regular strings as content */
.Vue-Toastification__toast.hypehub-toast .Vue-Toastification__toast-body {
    @apply text-sm;
}

.Vue-Toastification__toast--success.hypehub-toast {
    @apply font-semibold border rounded-lg text-spring-green bg-spring-green-darkest border-spring-green;
}

.Vue-Toastification__toast--error.hypehub-toast {
    @apply font-semibold border rounded-lg text-carmine bg-carmine-darkest border-carmine;
}


button {
    outline: none !important;
}

input {
    box-shadow: none !important;
    @apply placeholder-gray-500;
}

.lg-thumb-item.active {
    border-color: #FF0073 !important;
}

.v-select-dropdown.v-select-dropdown {
    @apply p-2.5 sm:p-3 rounded-md bg-deep-violet border border-electric-violet focus:ring-electric-violet focus:border-electric-violet focus:outline-none;
}

.v-select-dropdown .vs__search {
    @apply pl-0 m-0 placeholder-gray-500;
}

.v-select-dropdown .vs__dropdown-toggle {
    @apply p-0 m-0 border-none;
}

.v-select-dropdown .vs__selected {
    @apply pl-0 ml-0 text-white;
}

.v-select-dropdown .vs__dropdown-menu, .v-select-dropdown .vs__dropdown-toggle {
    @apply text-white bg-deep-violet;
}

.v-select-dropdown .vs__open-indicator {
    @apply text-white fill-current;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none !important;
    margin: 0 !important; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield !important; /* Firefox */
}

/* Doka Editor */

.DokaButton.DokaButtonExport {
    @apply inline-flex items-center justify-center px-8 py-3 text-sm font-semibold text-white border border-transparent rounded-full bg-electric-violet hover:bg-opacity-80 focus:outline-none;
    padding: 0.65rem 2rem !important;
    color: #fff !important;
    background-color: #8401FF !important;
    font-size: 16px !important;
    line-height: 1.25rem !important;
}

button[disabled] {
    @apply bg-opacity-75
}

button {
    outline: none !important;
}

.vs__actions .vs__clear {
    display: none !important;
}
